
import React from 'react';
import '../../src/xp.css';

function Xp() {

    return (
        <div className='container container-xp'>
            <h3 className='gradient-text'>Experiência</h3>
            <ul className='mt-3'>
                <li>
                    <div>
                        <p className='titulo-vaga'><i class="fa-solid fa-code-commit"></i> Innofarm tech - Programador PHP/Laravel</p>
                        <p style={{marginLeft: '20px'}}><i class="fa-regular fa-clock"></i>
                            &nbsp; Nov de 2024 - Trabalhando atualmente.
                        </p>
                        <i style={{marginLeft: '20px'}}> · 
                            &nbsp; Desenvolvimento Laravel MVC
                        </i><br/>
                        <i style={{marginLeft: '20px'}}> · 
                            &nbsp; FilamentPHP
                        </i><br/>
                        <i style={{marginLeft: '20px'}}> · 
                            &nbsp; Livewire
                        </i><br/>
                        <i style={{marginLeft: '20px'}}> · 
                            &nbsp; Tailwind
                        </i><br/>
                        <i style={{marginLeft: '20px'}}> · 
                            &nbsp; Banco de dados MySql 
                        </i>
                    </div>
                </li>
                <hr/>
                <li>
                    <div>
                        <p className='titulo-vaga'><i class="fa-solid fa-code-commit"></i> Blast WEB - Programador PHP/Laravel</p>
                        <p style={{marginLeft: '20px'}}><i class="fa-regular fa-clock"></i>
                            &nbsp; Dez de 2023 - Ago de 2024
                        </p>
                        <i style={{marginLeft: '20px'}}> · 
                            &nbsp; Desenvolvimento Laravel MVC
                        </i><br/>
                        <i style={{marginLeft: '20px'}}> · 
                            &nbsp; Livewire
                        </i><br/>
                        <i style={{marginLeft: '20px'}}> · 
                            &nbsp; Bootstrap 
                        </i><br/>
                        <i style={{marginLeft: '20px'}}> · 
                            &nbsp; JQuery 
                        </i><br/>
                        <i style={{marginLeft: '20px'}}> · 
                            &nbsp; Banco de dados MySql 
                        </i>
                    </div>
                </li>
                <hr/>
                <li>
                    <div>
                        <p className='titulo-vaga'><i class="fa-solid fa-code-commit"></i> Runy.me - Freelancer programador PHP/Laravel</p>
                        <p style={{marginLeft: '20px'}}><i class="fa-regular fa-clock"></i>
                            &nbsp; Abr de 2023 - Mai de 2023
                        </p>
                        <i style={{marginLeft: '20px'}}> · 
                            &nbsp; API Laravel
                        </i><br/>
                        <i style={{marginLeft: '20px'}}> · 
                            &nbsp;  Suporte Js/Vue.js
                        </i><br/>
                        <i style={{marginLeft: '20px'}}> · 
                            &nbsp; Ambiente de desenvolvimento em Docker
                        </i><br/>
                        <i style={{marginLeft: '20px'}}> · 
                            &nbsp; Banco de dados MySql 
                        </i>
                    </div>
                </li>
            </ul>
        </div>
    );
}

export default Xp;
